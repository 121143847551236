import { useCallback } from 'react';

import { useGlobalFiltersStore, usePartyComposition } from '@vakantiesnl/services/src/stores/globalFiltersStore';
import { isBetweenMinMaxDepartureDate } from '@vakantiesnl/services/src/util/dateUtils';
import { convertDurationsToArray } from '@vakantiesnl/services/src/util/durations';
import dayjs from 'dayjs';
import { cloneDeep } from 'lodash';

import { getInitialState, State } from './reducer';
import { getInitialDepartureDate, getInitialPartyComposition } from './utils';
import { TripFilterQueryParams } from '../../../context/useTripFilterContext';
import { getHistoryState } from '../../browser-history';
import { useGetAccommodationByPath } from '../../queries';

export const useTripFiltersInitializer = (query: TripFilterQueryParams): (() => State) => {
	const contextParty = usePartyComposition();
	const { accommodation } = useGetAccommodationByPath();
	const globalFilters = useGlobalFiltersStore((s) => s.filters);

	/** Initialize the party for acco page */
	const party = getInitialPartyComposition(query.pax, contextParty);

	return useCallback(() => {
		// Only set override durations based on accoOnly when no manual durations have been set
		const isShortDurationOverride = accommodation.isBookableQenner && !globalFilters.durations?.length;

		const initialState = cloneDeep(getInitialState(isShortDurationOverride));

		const historyState = getHistoryState<State>('tripFilterContext');
		if (historyState) {
			return { ...historyState, party };
		}

		if (query.airports) {
			initialState.airports = query.airports;
		} else {
			initialState.airports = [];
		}

		initialState.departureDate = getInitialDepartureDate(query.departureDate);

		if (query.overrideDurations) {
			initialState.durationsOverride = convertDurationsToArray(query.overrideDurations);
		}

		if (query.mealplans) {
			initialState.mealplans = query.mealplans;
		} else {
			initialState.mealplans = [];
		}

		initialState.party = party;

		initialState.minPrice = query.minPrice;
		initialState.maxPrice = query.maxPrice;

		initialState.airlines = query.airlines;
		initialState.flightProvider = query.flightProvider;
		initialState.tourOperators = query.tourOperators;

		const selectedDateDayjs = dayjs(query.selected.date);
		const isSelectedDateValid =
			selectedDateDayjs.isValid() && isBetweenMinMaxDepartureDate(selectedDateDayjs.toDate());

		initialState.selected = {
			...query.selected,
			date: isSelectedDateValid ? query.selected.date : undefined,
		};

		return initialState;
	}, [
		accommodation.isBookableQenner,
		globalFilters.durations,
		query.airports,
		query.departureDate,
		query.overrideDurations,
		query.mealplans,
		query.minPrice,
		query.maxPrice,
		query.selected,
		query.airlines,
		query.flightProvider,
		query.tourOperators,
		party,
	]);
};
