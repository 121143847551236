/* eslint-disable-next-line camelcase, @typescript-eslint/naming-convention */
import { DEFAULT_SHORT_DURATIONS_ARRAY } from '@vakantiesnl/services/src/util/durations';
import { Search } from '@vakantiesnl/types';

import { ActionTypes } from './actions';
import {
	UPDATE_AIRPORTS_TRIP_FILTER,
	UPDATE_DEPARTURE_DATE_TRIP_FILTER,
	UPDATE_DURATION_RANGE_TRIP_FILTER,
	UPDATE_MEALPAN_TRIP_FILTER,
	UPDATE_INVALID_PRESELECTION,
	UPDATE_SELECTED_TRIP,
	UPDATE_SELECTED_FLIGHT,
	UPDATE_SELECTED_ROOM_TYPE,
	UPDATE_SELECTED_MEAL_PLAN,
	UPDATE_IS_INITIAL_LOAD,
	RESET_TRIP_FILTER,
	UPDATE_SELECTED_DIRECT_ONLY,
} from './constants';
import { DEFAULT_PARTY } from '../../../util/partyUtils';

export type State = {
	airports?: string[];
	departureDate: string[] | null;
	durationsOverride: number[] | null;
	mealplans?: string[];
	referURL?: string;
	party: Search.PartyComposition[];
	invalidPreselection?: boolean;
	minPrice?: string;
	maxPrice?: string;
	selected: {
		date?: string;
		directOnly?: boolean;
		flight?: {
			outbound: string;
			inbound: string;
		};
		roomType?: string;
		mealPlan?: string;
	};
	isInitialLoad: boolean;
	tourOperators?: string[];
	airlines?: string[];
	flightProvider?: string;
};

export const getInitialState = (isShortDuration?: boolean): State => ({
	airports: [],
	departureDate: null,
	durationsOverride: isShortDuration ? DEFAULT_SHORT_DURATIONS_ARRAY : null,
	mealplans: [],
	party: DEFAULT_PARTY, // overriden in useTripFilterContext by PartyCompositionStore
	invalidPreselection: false,
	selected: {},
	isInitialLoad: true,
});

export const reducer = (state: State = getInitialState(), data: ActionTypes): State => {
	switch (data.type) {
		case UPDATE_AIRPORTS_TRIP_FILTER:
			return { ...state, airports: data.payload };
		case UPDATE_DEPARTURE_DATE_TRIP_FILTER:
			return { ...state, departureDate: data.payload };
		case UPDATE_DURATION_RANGE_TRIP_FILTER:
			return {
				...state,
				// Reset the duration override when a manual duration has been chosen
				durationsOverride: null,
			};
		case UPDATE_MEALPAN_TRIP_FILTER:
			return { ...state, mealplans: data.payload };
		case UPDATE_INVALID_PRESELECTION:
			return { ...state, invalidPreselection: data.payload };
		case UPDATE_SELECTED_TRIP:
			return { ...state, selected: { ...state.selected, date: data.payload } };
		case UPDATE_SELECTED_DIRECT_ONLY:
			return { ...state, selected: { ...state.selected, directOnly: data.payload } };
		case UPDATE_SELECTED_FLIGHT:
			return { ...state, selected: { ...state.selected, flight: data.payload } };
		case UPDATE_SELECTED_ROOM_TYPE:
			return { ...state, selected: { ...state.selected, roomType: data.payload } };
		case UPDATE_SELECTED_MEAL_PLAN:
			return { ...state, selected: { ...state.selected, mealPlan: data.payload } };
		case UPDATE_IS_INITIAL_LOAD:
			return { ...state, isInitialLoad: data.payload };
		case RESET_TRIP_FILTER:
			return {
				...getInitialState(data.payload),
			};
		default:
			return state;
	}
};
